import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouteChangeTracker: React.FC<RouteComponentProps> = ({ history }) => {
  //initial load
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);

  history.listen((location) => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
