import React from 'react';
import styled from 'styled-components';
import { NodeBlock, Slots, assert, tripetto } from 'tripetto-runner-foundation';
import {
  IAutoscrollRenderProps,
  IAutoscrollRendering,
  namespace,
} from 'tripetto-runner-autoscroll';
import Geosuggest, { Suggest } from 'react-geosuggest';

interface IAddress {
  isCityOnly?: boolean;
}

@tripetto({
  namespace,
  type: 'node',
  identifier: 'address',
})
export class Address
  extends NodeBlock<IAddress>
  implements IAutoscrollRendering
{
  readonly addressSlot = assert(this.valueOf<string, Slots.String>('address'));
  readonly citySlot = assert(this.valueOf<string, Slots.String>('city'));
  readonly stateSlot = assert(this.valueOf<string, Slots.String>('state'));
  readonly shortStateSlot = assert(
    this.valueOf<string, Slots.String>('short-state')
  );
  readonly streetSlot = this.valueOf<string, Slots.String>('street');
  readonly zipSlot = this.valueOf<string, Slots.String>('zip');
  readonly queryType = this.props.isCityOnly ? '(cities)' : 'address';

  readonly required = this.addressSlot.slot.required || false;

  render(props: IAutoscrollRenderProps): React.ReactNode {
    return (
      <>
        {/* <input type="date" placeholder="yyyy-MM-dd" /> */}
        {/* <style>
                    {'nav button:first-child {display:none}'} */}
        {/* hack that injects styling to the Tripetto runner - removes the "next" buttons */}
        {/* </style> */}
        {props.name}
        {props.description}

        <GeosuggestElement
          country={'us'}
          types={[this.queryType]}
          autoComplete="off"
          tabIndex={props.tabIndex}
          placeholder={props.placeholder}
          required={this.required}
          // onChange={(address: string) => { }}
          onSuggestSelect={(suggest?: Suggest) => {
            const AddressObj = suggest?.gmaps?.address_components;

            const streetNumber =
              AddressObj?.find((x) => x.types.indexOf('street_number') !== -1)
                ?.long_name || ''; //"street_number"

            const street =
              AddressObj?.find((x) => x.types.indexOf('route') !== -1)
                ?.long_name || ''; //"route"

            const city =
              AddressObj?.find(
                (x) => x.types.indexOf('sublocality_level_1') !== -1
              )?.long_name ||
              AddressObj?.find((x) => x.types.indexOf('locality') !== -1)
                ?.long_name ||
              AddressObj?.find(
                (x) => x.types.indexOf('administrative_area_level_2') !== -1
              )?.long_name ||
              ''; //"sublocality_level_1" || "locality" || "administrative_area_level_2"

            const shortState =
              AddressObj?.find(
                (x) => x.types.indexOf('administrative_area_level_1') !== -1
              )?.short_name || ''; // "administrative_area_level_1"

            const longState =
              AddressObj?.find(
                (x) => x.types.indexOf('administrative_area_level_1') !== -1
              )?.long_name || ''; // "administrative_area_level_1"

            const zip =
              AddressObj?.find((x) => x.types.indexOf('postal_code') !== -1)
                ?.short_name || ''; // "postal_code"

            this.addressSlot.value = this.props.isCityOnly
              ? `${city}, ${shortState}`
              : `${streetNumber} ${street}, ${city}, ${shortState}, ${zip}`;
            // uncomment to deserialize on the BE -  toJSON.stringify({ streetNumber, street, city, shortState, longState, zip });

            if (this.streetSlot)
              this.streetSlot.value = `${streetNumber} ${street}`;
            if (this.zipSlot) this.zipSlot.value = zip;

            this.citySlot.value = city;
            this.stateSlot.value = longState;
            this.shortStateSlot.value = shortState;
          }}
        />
      </>
    );
  }
}

//styled-components
const GeosuggestElement = styled(Geosuggest)`
  > div:first-child {
    > input {
      appearance: none;
      outline: none;
      box-sizing: border-box;
      display: block;
      width: 100%;
      font-size: 1em;
      line-height: 1.5em;
      background-color: white;
      background-repeat: no-repeat;
      background-size: ${8 / 7}em;
      background-position: right 0.375em center;
      border: 1px solid #f4f0ed;
      color: black;
      border-radius: 0.5em;
      padding: 0.375em 0.75em;
      margin: 0;
      height: 45px;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        opacity 0.15s ease-in-out;
    }
  }

  > div:last-child {
    > ul {
      padding: 0;
      margin: 0;
      border-radius: 0.5em;

      li {
        list-style: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1e;
        color: #fff;
        border-bottom: 1px solid #fff;
        background-color: #2522fc;
        padding: 10px;
      }
    }

    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }

    .geosuggest__item--active {
      background: #1c7cd6;
      color: #fff;
    }
  }
`;
