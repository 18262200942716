import mixpanel, { Dict } from 'mixpanel-browser';

if (process.env.REACT_APP_RUNNER_MIXPANEL_ID) {
  mixpanel.init(process.env.REACT_APP_RUNNER_MIXPANEL_ID ?? '');
}

const actions = {
  reportPageView: (pageName: string): void => {
    process.env.REACT_APP_RUNNER_MIXPANEL_ID &&
      mixpanel.track(`View ${pageName}`);
  },
  reportClick: (cta: string): void => {
    process.env.REACT_APP_RUNNER_MIXPANEL_ID && mixpanel.track(`Click ${cta}`);
  },
  identify: (id: string): void => {
    process.env.REACT_APP_RUNNER_MIXPANEL_ID && mixpanel.identify(id);
  },
  alias: (id: string): void => {
    process.env.REACT_APP_RUNNER_MIXPANEL_ID && mixpanel.alias(id);
  },
  reportEvent: (event: string, props?: Dict): void => {
    process.env.REACT_APP_RUNNER_MIXPANEL_ID && mixpanel.track(event, props);
  },
  people: {
    set: (props: Dict): void => {
      process.env.REACT_APP_RUNNER_MIXPANEL_ID && mixpanel.people.set(props);
    },
  },
};

// while we're here let's add gtag to the global window object
declare global {
  interface Window {
    dataLayer: any;
    SessionStack: any;
  }
}

export const analyticsReporter = actions;
