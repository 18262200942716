import React, { useState } from 'react';
import { AutoscrollRunner } from 'tripetto-runner-autoscroll';
import { Export } from 'tripetto-runner-foundation';
import { intakeApi } from '../api/intake-api';
import IFormSubmissionResult from '../interfaces/form-submission-result';
import Alert from 'antd/lib/alert';
import 'antd/dist/antd.css';
import { analyticsReporter } from '../services/analytics-reporter';
import runnerStyle from '../config/runner-style';
import { Form } from '../models/Form';
import { urlManager } from '../services/url-manager';

import '@injurycom/common/components/blocks/address/address';
import '@injurycom/common/components/blocks/fuzzy-search/fuzzy-search';
import '@injurycom/common/components/blocks/pii-email/pii-email';
import '@injurycom/common/components/blocks/pii-phone/pii-phone';
import '@injurycom/common/components/blocks/pii-text/pii-text';

interface RunnerProps {
  form: Form;
}

const FormRunner: React.FC<RunnerProps> = ({ form }) => {
  const [isError, setIsError] = useState<boolean>(false);

  const query = urlManager.useQuery();
  const intakeId = query.get('pid');

  return (
    <>
      {isError && (
        <Alert
          message="Error"
          description="Something went wrong. Please reload and try again."
          type="error"
          showIcon
        />
      )}
      {!isError && (
        <AutoscrollRunner
          customStyle={{ height: '87vh', position: 'relative' }}
          definition={form.json}
          display={'page'}
          styles={runnerStyle}
          // onPause = {instance => {}}   //pause button appears on progress bar
          view={'live'}
          onSubmit={(instance) => {
            if (form.slug === 'get-started') {
              window.location.replace(`${process.env.REACT_APP_SITE_URL}/schedule-sign-up/`);
            } else {
              analyticsReporter.reportClick('Submit Form');

              const submissionJson = Export.exportablesWithData(instance);
              intakeApi
                .postIntake(form.id, submissionJson, intakeId)
                .then((result: IFormSubmissionResult | null) => {
                  if (!result) {
                    setIsError(true);
                  } else {
                    analyticsReporter.identify(result.intakeId);

                    if (result.status) {
                      window.SessionStack.identify({
                        userId: result.intakeId,
                        status_str: 'Completed Packet'
                      });
                      
                      analyticsReporter.reportEvent('Completed Packet', {
                        status: result.status,
                      });

                      if (result.status === 'Sign Up in Progress') {
                        window.dataLayer.push({'event': 'Signup In Progress'});
                      }
                    }

                    window.location.replace(result.redirectUrl);
                  }
                });
            }
          }}
        />
      )}
    </>
  );
};

export default FormRunner;
