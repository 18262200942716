import React, { useState } from 'react';
import { useFuzzy } from 'react-use-fuzzy';
import styled from 'styled-components';
import { IFuzzySearchOption } from './option';

interface FuzzyWrapperProps {
  onSelect: (selected: IFuzzySearchOption) => void;
  options: IFuzzySearchOption[];
  placeholder: string;
}

const FuzzyWrapper: React.FC<FuzzyWrapperProps> = ({
  onSelect,
  options,
  placeholder,
}) => {
  const [userInput, setUserInput] = useState<string>('');
  const { result, keyword, search, resetSearch } = useFuzzy<IFuzzySearchOption>(
    options,
    {
      keys: ['name'],
      caseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      threshold: 0.5,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
    }
  );

  return (
    <FuzzyContainer>
      <InputField
        type="text"
        placeholder={placeholder}
        value={userInput}
        onChange={(e) => {
          setUserInput(e.target.value);
          search(e.target.value);
        }}
      />
      <DrowdownList>
        {keyword &&
          result.map((option) => (
            <DropdownOption
              key={option.id}
              onClick={() => {
                setUserInput(option.name);
                resetSearch();
                onSelect(option);
              }}
            >
              {option.name}
            </DropdownOption>
          ))}
      </DrowdownList>
    </FuzzyContainer>
  );
};

const DrowdownList = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 0 !important;
  border-radius: 0.5em;
`;

const DropdownOption = styled.li`
  list-style: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1e;
  color: #fff;
  border-bottom: 1px solid #fff;
  background-color: #2522fc;
  padding: 10px;
`;

const InputField = styled.input`
  appearance: none;
  outline: none;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.5em;
  background-color: white;
  background-repeat: no-repeat;
  background-size: ${8 / 7}em;
  background-position: right 0.375em center;
  border: 1px solid #f4f0ed;
  color: black;
  border-radius: 0.5em;
  padding: 0.375em 0.75em;
  margin: 0;
  height: 45px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
`;

const FuzzyContainer = styled.div`
  > div:first-child > * {
    margin-top: 0px !important;
  }
`;

export default FuzzyWrapper;
