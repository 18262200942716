import { IAutoscrollStyles } from 'tripetto-runner-autoscroll';

const runnerStyle: IAutoscrollStyles = {
  //todo: move full config to separate file after design
  showPreviousButton: false,
  showSeparateSubmit: false,
  showNavigation: 'never',
  // font: { family: 'Roboto' },
  noBranding: true,
  background: { color: '#F4F0ED' },
  buttons: {
    baseColor: '#2522FC',
    mode: 'fill',
    roundness: 100,
  },
  inputs: {
    borderColor: 'F4F0ED',
    backgroundColor: 'white',
    agreeColor: '#01BF92',
    declineColor: '#E62043',
    selectionColor: '#2522FC',
  },
  navigation: {
    backgroundColor: 'white',
    progressbarColor: '#2522FC',
  },
};

export default runnerStyle;
