import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

const reportError = (message: string): void => {
  if (process.env.NODE_ENV === 'development')
    //remove this to test sentry in dev mode
    console.log(message);
  else Sentry.captureMessage(message, { level: Severity.Error });
};

export const logger = {
  reportError,
};
