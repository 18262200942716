/* eslint-disable no-useless-escape */
import React from 'react';
import {
  tripetto,
  validator,
  NodeBlock,
  Slots,
  assert,
} from 'tripetto-runner-foundation';
import {
  IAutoscrollRenderProps,
  IAutoscrollRendering,
  namespace,
} from 'tripetto-runner-autoscroll';
import { TValidation } from './validation';

import { RawInput } from '../infra/RawInput';

const IS_RFC822 =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const IS_RFC5322 =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface IPIIEmail {
  readonly validation?: TValidation;
  readonly isPII?: boolean;
}

@tripetto({
  namespace,
  type: 'node',
  identifier: 'pii-email',
})
export class PIIEmail
  extends NodeBlock<IPIIEmail>
  implements IAutoscrollRendering
{
  readonly emailSlot = assert(this.valueOf<string, Slots.String>('email'));

  readonly required = this.emailSlot.slot.required || false;

  @validator
  validate(): boolean {
    return (
      !this.emailSlot.string ||
      (this.props.validation === 'rfc822' ? IS_RFC822 : IS_RFC5322).test(
        this.emailSlot.value
      )
    );
  }

  render(props: IAutoscrollRenderProps, done?: () => void): React.ReactNode {
    return (
      <span className={this.props.isPII ? 'pii-field' : 'field'}>
        {props.name}
        {props.description}
        <RawInput
          id={props.id}
          type="email"
          required={this.required}
          tabIndex={props.tabIndex}
          placeholder={props.placeholder || '@'}
          onFocus={props.focus()}
          onBlur={props.blur()}
          onSubmit={done}
          onChange={(e) => {
            this.emailSlot.value = e.target.value;
          }}
        ></RawInput>
      </span>
    );
  }
}
