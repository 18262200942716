import styled from 'styled-components';

export const RawInput = styled.input`
  appearance: none;
  outline: none;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1em;
  line-height: 1.5em;
  background-color: white;
  background-repeat: no-repeat;
  background-size: ${8 / 7}em;
  background-position: right 0.375em center;
  border: 1px solid #f4f0ed;
  color: black;
  border-radius: 0.5em;
  padding: 0.375em 0.75em;
  margin: 0;
  margin-top: 1em;
  height: 45px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
`;
