import React, { useState, useEffect } from 'react';
import { analyticsReporter } from '../services/analytics-reporter';
import { getAxios } from '../services/axios';
import { urlManager } from '../services/url-manager';
import Alert from 'antd/lib/alert';

const Completion: React.FC = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const query = urlManager.useQuery();

  useEffect(() => {
    const packetId = query.get('pid');
    const docusignEvent = query.get('event');
    if (!packetId || !docusignEvent || docusignEvent !== 'signing_complete') {
      analyticsReporter.reportEvent('Docusign Callback Error', {
        params: query,
      });
      setIsError(true);
    } else {
      getAxios()
        .get(`/intakes/${packetId}/docusignCallback`)
        .then(() => {
          analyticsReporter.identify(packetId);
          analyticsReporter.reportEvent('Completed Docusign');
          window.SessionStack.identify({
            userId: packetId,
            status_str: 'Completed Docusign'
          });

          window.location.replace(`${process.env.REACT_APP_SITE_URL}/sign-up/`);
        })
        .catch((ex) => {
          setIsError(true); // transparent
        });
    }
  }, [query]);

  return (
    <>
      {isError && (
        <Alert
          message="Error"
          description="Something went wrong. Please try again."
          type="error"
          showIcon
        />
      )}{' '}
    </>
  );
};

export default Completion;
