import { getAxios } from '../services/axios';
import { logger } from '../services/logger';
import { Export } from 'tripetto-runner-foundation';
import IFormSubmissionResult from '../interfaces/form-submission-result';

const endpoint = '/intakes';

const postIntake = async (
  formId: string,
  submission: Export.IExportables,
  packetId: string | null
): Promise<IFormSubmissionResult | null> => {
  let result: IFormSubmissionResult | null = null;

  const url = packetId ? `${endpoint}/${packetId}` : endpoint;

  await getAxios()
    .post(url, {
      formId,
      submission,
    })
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        result = response.data;
      } else {
        logger.reportError(
          `PUT ${url} - failed with status code ${response.status}`
        );
      }
    })
    .catch((ex) => {
      logger.reportError(`PUT ${url} - failed with error ${ex}`);
    });

  return result;
};

export const intakeApi = {
  postIntake,
};
