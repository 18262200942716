import React from 'react';

import {
  NodeBlock,
  Slots,
  tripetto,
  arrayItem,
  assert,
  findFirst,
  markdownifyToString,
} from 'tripetto-runner-foundation';
import {
  IAutoscrollRenderProps,
  IAutoscrollRendering,
  namespace,
} from 'tripetto-runner-autoscroll';
import { IFuzzySearchOption } from './option';
import FuzzyWrapper from './fuzzy-wrapper';

@tripetto({
  namespace,
  type: 'node',
  identifier: 'fuzzysearch',
})
export class FuzzySearch
  extends NodeBlock<{
    readonly options?: IFuzzySearchOption[];
  }>
  implements IAutoscrollRendering
{
  readonly dropdownSlot = assert(
    this.valueOf<string, Slots.String>('option', 'static')
  ).confirm();

  readonly required = this.dropdownSlot.slot.required || false;

  readonly placeholderText = this.node.placeholder || '';

  get options(): IFuzzySearchOption[] {
    return (
      this.props.options?.map((option) => ({
        ...option,
        name: markdownifyToString(option.name, this.context),
      })) || []
    );
  }

  /** Retrieves the reference of the selection option. */
  get value(): string {
    const selected = findFirst(
      this.props.options,
      (option) => option.id === this.dropdownSlot.reference
    );

    if (!selected && !this.node.placeholder) {
      return this.select(arrayItem(this.props.options, 0));
    }

    return (selected && selected.id) || '';
  }

  /** Sets the reference of the selected option. */
  set value(reference: string) {
    this.select(
      findFirst(this.props.options, (option) => option.id === reference)
    );
  }

  /** Selects an option. */
  select(option: IFuzzySearchOption | undefined): string {
    this.dropdownSlot.set(
      option && (option.value || option.name),
      option && option.id
    );

    return (option && option.id) || '';
  }

  render(props: IAutoscrollRenderProps): React.ReactNode {
    return (
      <>
        {props.name}
        <FuzzyWrapper
          placeholder={this.placeholderText}
          options={this.options}
          onSelect={(selected: IFuzzySearchOption) => {
            this.select(selected);
          }}
        ></FuzzyWrapper>
      </>
    );
  }
}
