import React from 'react';
import styled from 'styled-components';
import { Logo } from '@injurycom/common/components/logo/Logo';
import { Container } from '@injurycom/common/components/container/Container';

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <Container>
        <Logo url={process.env.REACT_APP_RUNNER_URL || '/'} />
      </Container>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;

  @media (max-width: 767px) {
    width: 90vw;
  }
`;

export default Header;
