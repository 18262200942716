/* eslint-disable no-useless-escape */
import React from 'react';
import {
  NodeBlock,
  Slots,
  assert,
  validator,
  tripetto,
} from 'tripetto-runner-foundation';
import './condition';
import { RawInput } from '../infra/RawInput';
import {
  IAutoscrollRendering,
  IAutoscrollRenderProps,
  namespace,
} from 'tripetto-runner-autoscroll';

/* tslint:disable-next-line:max-line-length */
const IS_PHONE_NUMBER =
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;

interface IPIIPhone {
  readonly isPII?: boolean;
}

@tripetto({
  namespace,
  type: 'node',
  identifier: 'pii-phone',
})
export class PIIPhone
  extends NodeBlock<IPIIPhone>
  implements IAutoscrollRendering
{
  readonly phoneNumberSlot = assert(
    this.valueOf<string, Slots.String>('phone-number')
  );

  readonly required = this.phoneNumberSlot.slot.required || false;

  @validator
  validate(): boolean {
    return (
      !this.phoneNumberSlot.string ||
      IS_PHONE_NUMBER.test(this.phoneNumberSlot.value)
    );
  }

  render(props: IAutoscrollRenderProps, done?: () => void): React.ReactNode {
    return (
      <span className={this.props.isPII ? 'pii-field' : 'field'}>
        {props.name}
        {props.description}
        <RawInput
          type="phone"
          id={props.id}
          required={this.required}
          tabIndex={props.tabIndex}
          placeholder={props.placeholder}
          onFocus={props.focus()}
          onBlur={props.blur()}
          onSubmit={done}
          onChange={(e) => {
            this.phoneNumberSlot.value = e.target.value;
          }}
        />
      </span>
    );
  }
}
