import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import RouterChangeTracker from './components/RouterChangeTracker';
import App from './components/App';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID ?? '');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RouterChangeTracker />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// https://bit.ly/CRA-vitals
// reportWebVitals();
