import { getAxios } from '../services/axios';
import { logger } from '../services/logger';
import { Form } from '../models/Form';

const formDefinitionEndpoint = '/forms';

const getFormDefinition = async (slug: string): Promise<Form | null> => {
  let result: Form | null = null;
  const url = `${formDefinitionEndpoint}/${slug}`;
  await getAxios()
    .get(url)
    .then((response) => {
      if (response.status === 200 && response.data != null) {
        result = response.data;
      } else {
        logger.reportError(
          `${formDefinitionEndpoint} - get -  failed with status code ${response.status} for slug ${slug}`
        );
      }
    })
    .catch((ex) => {
      logger.reportError(
        `${formDefinitionEndpoint} - get - failed with error ${ex}`
      );
    });

  return result;
};

export const formApi = {
  getFormDefinition,
};
