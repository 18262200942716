import React from 'react';
import ApplicationForm from './ApplicationForm';
import { Route, Switch } from 'react-router-dom';
import Completion from './Completion';
import Header from './Header';
import Footer from './Footer';
import 'antd/dist/antd.css';
import '../App.css';

const App: React.FC = () => {
  return (
    <>
      <Header></Header>
      <Switch>
        <Route path="/complete" component={Completion} />
        <Route path="/:slug" component={ApplicationForm} />
        <Route exact path="/" component={ApplicationForm} />
      </Switch>
      <Footer></Footer>
    </>
  );
};

export default App;
