import {
  ConditionBlock,
  condition,
  tripetto,
} from 'tripetto-runner-foundation';
import { TMode } from './mode';

@tripetto({
  type: 'condition',
  identifier: 'pii-phone',
})
export class PhoneNumberCondition extends ConditionBlock<{
  readonly mode: TMode;
}> {
  @condition
  verify(): boolean {
    const phoneNumberSlot = this.valueOf<string>();

    if (phoneNumberSlot) {
      switch (this.props.mode) {
        case 'defined':
          return phoneNumberSlot.string !== '';
        case 'undefined':
          return phoneNumberSlot.string === '';
      }
    }

    return false;
  }
}
