import { useLocation } from 'react-router-dom';

const getFullUrl = (endpoint: string): string =>
  `${process.env.REACT_APP_API_URL}${endpoint}`;

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const urlManager = {
  getFullUrl,
  useQuery,
};
