import React from 'react';
import {
  NodeBlock,
  Slots,
  assert,
  findFirst,
  isString,
  validator,
  tripetto,
} from 'tripetto-runner-foundation';
import { TAutoComplete } from './autocomplete';
import './conditions/text';
import './conditions/score';
import { RawInput } from '../infra/RawInput';
import {
  IAutoscrollRendering,
  IAutoscrollRenderProps,
  namespace,
} from 'tripetto-runner-autoscroll';

interface IPIIText {
  readonly autoComplete?: TAutoComplete;
  readonly prefill?: string;
  readonly minLength?: number;
  readonly useSuggestions?: boolean;
  readonly suggestions?: {
    readonly id: string;
    readonly name: string;
    readonly score?: number;
  }[];
}

@tripetto({
  namespace,
  type: 'node',
  identifier: 'pii-text',
})
export class PIIText
  extends NodeBlock<IPIIText>
  implements IAutoscrollRendering
{
  readonly scoreSlot = this.valueOf<number, Slots.Numeric>('score', 'feature');

  readonly textSlot = assert(
    this.valueOf<string, Slots.Text>('value', 'static', {
      prefill:
        (isString(this.props.prefill) && {
          value: this.props.prefill,
        }) ||
        undefined,
      modifier: (data) => {
        if (this.props.useSuggestions) {
          return {
            value: data.value,
            reference:
              (data.value &&
                findFirst(
                  this.props.suggestions,
                  (suggestion) => suggestion.name === data.value
                )?.id) ||
              undefined,
          };
        }
      },
      onChange: (slot) => {
        if (this.scoreSlot) {
          const selected =
            (this.props.useSuggestions &&
              findFirst(
                this.props.suggestions,
                (suggestion) => suggestion.name === slot.value
              )) ||
            undefined;

          this.scoreSlot.set(selected && (selected?.score || 0));
        }
      },
    })
  );

  readonly required = this.textSlot.slot.required || false;
  readonly maxLength = this.textSlot.slot.maxLength;
  readonly autoComplete?: TAutoComplete = this.props.autoComplete;

  @validator
  validate(): boolean {
    return (
      !this.props.minLength ||
      this.textSlot.value.length >= this.props.minLength
    );
  }

  render(props: IAutoscrollRenderProps, done?: () => void): React.ReactNode {
    return (
      <span className={this.props.isPII ? 'pii-field' : 'field'}>
        {props.name}
        {props.description}
        <RawInput
          id={props.id}
          required={this.required}
          tabIndex={props.tabIndex}
          placeholder={props.placeholder}
          maxLength={this.maxLength}
          autoComplete={this.autoComplete}
          onFocus={props.focus()}
          onBlur={props.blur()}
          onSubmit={done}
          onChange={(e) => {
            this.textSlot.value = e.target.value;
          }}
        />
      </span>
    );
  }
}
