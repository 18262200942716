import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import { ReactComponent as LogoSVG } from '@injurycom/common/assets/logo.svg';

export const LogoLink = styled.a`
  text-decoration: none;
  margin-top: 5px;
  display: inline-block;
  line-height: 1;

  svg {
    width: 100%;
    max-width: 177px;

    @media screen and (max-width: 1024px) {
      max-width: 150px;
    }
  }
`;

interface LogoProps {
  url: string;
  style?: CSSProperties;
}

export const Logo = ({ url, style }: LogoProps): JSX.Element => {
  return (
    <LogoLink href={url} style={style}>
      <LogoSVG aria-label="Injury.com logo" />
    </LogoLink>
  );
};
