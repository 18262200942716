import React, { useState } from 'react';
import styled from 'styled-components';
import SlidingPane from 'react-sliding-pane';
import { CloseCircleOutlined } from '@ant-design/icons';
import 'react-sliding-pane/dist/react-sliding-pane.css';

const Footer: React.FC = () => {
  const privacyPolicyUrl = `${process.env.REACT_APP_SITE_URL}/privacy-policy/`;
  const tosUrl = `${process.env.REACT_APP_SITE_URL}/terms-of-service/`;

  const [isNoticeOpen, setIsNoticeOpen] = useState(false);

  return (
    <>
      <RawFooter>
        <FooterLink onClick={() => setIsNoticeOpen(true)}>
          Legal Notice{' '}
        </FooterLink>
        <FooterLink href={tosUrl} target="_blank">
          Terms & Conditions
        </FooterLink>
        <FooterLink href={privacyPolicyUrl} target="_blank">
          Privacy Policy{' '}
        </FooterLink>
      </RawFooter>
      <SlidingPane
        isOpen={isNoticeOpen}
        onRequestClose={() => setIsNoticeOpen(false)}
        from="bottom"
        width="100%"
        hideHeader={true}
      >
        <LegalNoticePanel>
          <CloseButtonContainer>
            <CloseCircleOutlined onClick={() => setIsNoticeOpen(false)} />
          </CloseButtonContainer>
          © 2021 All rights reserved.
          <br />
          <br />
          A qualified attorney will review each case for <br />
          which a retainer agreement is signed by a<br />
          potential client prior to the firm accepting any <br />
          client representation. <br /> <br />
          Morgan & Morgan
          <br />
          10 N Orange Ave, Suite 1600, Orlando, FL 32801 <br />
          {/* FAQs Contact Us */}
        </LegalNoticePanel>
      </SlidingPane>
    </>
  );
};

const FooterLink = styled.a`
  font-weight: normal;
  color: #2522fc;
  font-size: 0.625rem;
  display: inline-block;
  margin: auto;
  padding: 0.2rem 1rem;

  :hover {
    color: #2522fc;
    text-decoration: underline;
  }
`;

const RawFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(37, 34, 252, 0.04);
  text-align: center;
`;

const LegalNoticePanel = styled.div`
  text-align: center;
  font-size: 0.625rem;
`;

const CloseButtonContainer = styled.div`
  float: right;
  > span svg {
    height: 27px;
    width: 27px;
  }
`;

export default Footer;
