import React, { useEffect, useState } from 'react';
import FormRunner from './FormRunner';
import { formApi } from '../api/formApi';
import { analyticsReporter } from '../services/analytics-reporter';
import { Form } from '../models/Form';
import { logger } from '../services/logger';
import { useParams } from 'react-router-dom';
import 'antd/dist/antd.css';
interface RouteParams {
  slug: string;
}
const ApplicationForm: React.FC = () => {
  const [form, setForm] = useState<Form | null>();
  const params = useParams<RouteParams>();
  const slug = params.slug || process.env.REACT_APP_DEFAULT_FORM_SLUG || '';

  useEffect(() => {
    if (slug === 'start') {
      window.history.pushState(null, '', `/${slug}${window.location.search}`);
    }

    analyticsReporter.reportPageView(`/${slug}`);

    formApi.getFormDefinition(slug).then((form: Form | null) => {
      if (form === null || form.json == null) {
        logger.reportError(
          `getFormDefinition(${slug}) returned invalid form - ${form}`
        );
        analyticsReporter.reportEvent('Form not found', { slug });
        window.location.replace(`${process.env.REACT_APP_SITE_URL}/404`);
      } else {
        setForm(form);
      }
    });
  }, [slug]);

  return <>{form && <FormRunner form={form} />}</>;
};

export default ApplicationForm;
